"use client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useSelectAuthUser,
  useSelectCsrfToken,
  useSelectAddFavourites,
  useSelectShowFavouritesNumbers,
  useDispatchAddFavouritesModal,
  useDispatchShowLoader,
  useDispatchAddShowFavouritesNumbers,
  useSelectGameGroups,
} from "@/hooks";
import { DIGITS } from "@/utils/constants";

export default function AddFavouritesModal({ content = {} }) {
  const addFavourites = useSelectAddFavourites();
  const favouriteNumbersList = useSelectShowFavouritesNumbers();
  const authUser = useSelectAuthUser();
  const csrfToken = useSelectCsrfToken();
  const dispatchAddFavouritesModal = useDispatchAddFavouritesModal();
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchAddShowFavouritesNumbers =
    useDispatchAddShowFavouritesNumbers();
    const gameGroups = useSelectGameGroups();
  const gameGroupId = gameGroups[0]?.gameGroupID;
  const [favouriteName, setFavouriteName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  if (!authUser?.profile?.dateOfBirth) return;
    // Ensure favoriteNumbers and favouritesLimit are properly set
    const favouriteNumbers = addFavourites?.favouriteNumbers || [];
    const favouritesLimit = parseInt(content?.favouritesLimit) || 10;
  if (Object.keys(content).length < 1) content = addFavourites?.content || {};
  const handleErrors = () => {
    const isNumberInFavourite = favouriteNumbersList.some(fav => 
      fav.numbers === favouriteNumbers.toString()
    );
    const isNameInFavourite = favouriteNumbersList.some(fav => 
      fav.displayName === favouriteName
    );
    let errorMessage = null;

    switch(true) {
      case favouriteNumbersList.length >= favouritesLimit:
        errorMessage = content?.limitExceededMsg;
        break;
      case !favouriteName :
        errorMessage = content?.favNameNotThere;
        break;
      case isNameInFavourite:
        errorMessage = content?.favNameAlreadyThere;
        break;
      case isNumberInFavourite:
        errorMessage = content?.favNoThere;
        break;
      default: 
        errorMessage = '';
    }

    return { errorMessage, favouriteNumbers }
  }
  return (
    <>
      <div
        className="remodal-overlay remodal-is-opened"
        style={{
          display: !!addFavourites?.isOpened ? "block" : "none",
        }}
      ></div>
      <div
        className="remodal-wrapper remodal-is-opened"
        style={{
          display: !!addFavourites?.isOpened ? "block" : "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
          dispatchAddFavouritesModal(false);
        }}
      >
        <div
          className={
            "remodal modal modal--addfav remodal-is-initialized" +
            (!!addFavourites?.isOpened
              ? " remodal-is-opened"
              : " remodal-is-closed")
          }
          data-remodal-id="modal-verification"
          tabIndex="-1"
        >
          <button
            className="remodal-close"
            data-remodal-action="close"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setFavouriteName("");
              setErrorMsg("");
              dispatchAddFavouritesModal(false);
            }}
          >
            <i className="fal fa-times"></i>
          </button>
          <h2 className="modal__heading">{content?.title || ""}</h2>
          <form
            method="POST"
            id="form_add-favourite"
            onSubmit={async (e) => {
              e.preventDefault();
                try {
                  
                  const  { errorMessage, favouriteNumbers } = handleErrors();
                  if(errorMessage) {
                    setErrorMsg(errorMessage)
                    return;
                  }
                  dispatchShowLoader(true);
                  const response = await sendInternalPostRequest({
                    endpoint: "/api/favourite-numbers",
                    data: {
                      displayName: favouriteName,
                      newNumbers: favouriteNumbers,
                      gameGroupId: gameGroupId,
                    },
                    csrfToken,
                  });
            
                  if (response?.success && !!response?.id) {
                    // Reset form fields and update the state
                    setFavouriteName("");
                    setErrorMsg("");
                    dispatchAddShowFavouritesNumbers({
                      playerFavouriteNumbersID: response?.id || 0,
                      displayName: favouriteName,
                      numbers: favouriteNumbers.join(","),
                    });
                    dispatchAddFavouritesModal(false);
                  }
                  else {
                    setErrorMsg(response?.message);
                  }
                } catch (error) {  
                  setErrorMsg(error);
                } finally {
                  dispatchShowLoader(false);
                }
            }}
            
          >   
            <div className="form-group">
              <input
                type="text"
                value={favouriteName}
                placeholder={content?.FavNamePlaceholder}
                onChange={(e) => {
                  const value = e?.target?.value || "";
                  setFavouriteName(value);
                }}
                maxLength={DIGITS.EIGHT}
                className="digits-iq"
                onClick={(e) => e.stopPropagation()}
              />
              <div className="error-text"><p>{errorMsg}</p></div>
            </div>
            <div className="form-group form-group__buttons">
              <a
                className="btn btn--secondary-border"
                href={content?.skipButton?.link || ""}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFavouriteName("");
                  setErrorMsg("");
                  dispatchAddFavouritesModal(false);
                }}
              >
                {content?.skipButton?.text || ""}
              </a>
              <button
                className={"btn btn--primary"}
                type="submit"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {content?.saveButton?.text || ""}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

AddFavouritesModal.propTypes = {
  /**
   * Content data
   */
  content: PropTypes.object,
};
