"use client";
import PropTypes from "prop-types";
import FooterMobileMenu from "./menu";
import "./footer-mobile.css";

export default function FooterMobile({ mobileMenus = [] }) {
  return (
    <section className="footer-mobile">
      <div className="container">
        <div className="footer-mobile__content">
          <FooterMobileMenu mobileMenus={mobileMenus} />
        </div>
      </div>
    </section>
  );
}

FooterMobile.propTypes = {
  /**
   * Navigation menu items for mobile
   */
  mobileMenus: PropTypes.array.isRequired,
};
