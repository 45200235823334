"use client";
import Link from "next/link";
import Image from "next/image";
import PropTypes from "prop-types";
import FooterMenu from "./menu";
import FooterSocials from "./socials";
import parse from "html-react-parser";
import { useSelectLocale } from "@/hooks";
import {checkNumberAndWrap } from "@/utils/functions.client";
// Images
import scrollIcon from "@/app/[lang]/img/scroll-icon-ktc.svg";

export default function FooterDesktop({
  footerMenus,
  socialLinks,
  contactInfo,
  footerLogo,
  copyright,
}) {
  const locale = useSelectLocale();

  return (
    <footer className="footer" id="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__nav">
            <div className="footer__nav--text desktop">
              {/* <Link href={"/" + (locale || "")}>
                <div
                  className="footer__nav--img"
                  style={{
                    backgroundImage: "url(" + footerLogo + ")",
                  }}
                ></div>
              </Link> */}
              <FooterMenu footerMenus={footerMenus} />
            </div>
            <FooterSocials
              socialLinks={socialLinks}
              contactInfo={contactInfo}
            />
            <div className="footer__nav--text mobile">
              <FooterMenu footerMenus={footerMenus} />
            </div>
          </div>
          <div className="footer__bottom">
            <p className="bold-iq">
              {parse(checkNumberAndWrap(copyright,"text") || "")}
              <Image
                src={scrollIcon}
                alt="Scroll"
                width={1}
                height={1}
                loading="lazy"
              />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

FooterDesktop.propTypes = {
  /**
   * Navigation menu items
   */
  footerMenus: PropTypes.array,
  /**
   * Footer social links
   */
  socialLinks: PropTypes.object,
  /**
   * Footer Contact info
   */
  contactInfo: PropTypes.object,
  /**
   * Footer Logo
   */
  footerLogo: PropTypes.string,
  /**
   * Footer Logo
   */
  copyright: PropTypes.string,
};
