"use client";
import Image from "next/image";
import Link from "next/link";
import PropTypes from "prop-types";

export default function FooterContact({ contactInfo }) {
    let image = "";
    if (!!contactInfo?.image) image = contactInfo.image;
    else contactInfo?.details?.image || "";

    return (
        <div className="footer__socials--item">
            <p>{contactInfo?.call_to_action || ""} </p>
            <div className="footer__socials--platforms">
                <Link href={contactInfo?.details?.link || "#"}>
                    <Image
                        src={image || ""}
                        style={{ width: "24px", height: "24px" }}
                        width={24}
                        height={24}
                        alt={contactInfo?.call_to_action || ""}
                        loading="lazy"
                    />
                </Link>
            </div>
        </div>
    );
}

FooterContact.propTypes = {
    /**
     * Footer Contact info
     */
    contactInfo: PropTypes.object,
};
