"use client";
import PropTypes from "prop-types";
import { useDispatchSetLocale, useSelectLocale } from "@/hooks";
import "@/app/[lang]/styles/style.css";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";

export default function Countries({ locales = [] }) {
  const lang = useSelectLocale();
  const dispatchSetLocale = useDispatchSetLocale();
  const locale = locales.find(({ code }) => lang === code);
  const anotherLocale = locales.find(({ code }) => lang !== code);
  const [language, setLanguage] = useState();
  const path = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (path.split("/")[1] === `${locale?.code}`) {
      setLanguage(anotherLocale?.name);
    } else {
      setLanguage(locale?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, anotherLocale]);

  const handleClick = (e) => {
    e.preventDefault();
    if (locale && anotherLocale) {
      let newPath = path;
      if (newPath.includes(`/${locale?.code}`)) {
        newPath = newPath.replace(
          `/${locale?.code}`,
          `/${anotherLocale?.code}`
        );
        router.push(newPath, undefined, { locale: anotherLocale?.code });
        setLanguage(anotherLocale?.name);
        dispatchSetLocale(anotherLocale?.code);
      } else {
        newPath = newPath.replace(
          `/${anotherLocale?.code}`,
          `/${locale?.code}`
        );
        router.push(newPath, undefined, { newLocale: locale });
        setLanguage(locale?.name);
        dispatchSetLocale(locale?.code);
      }
    }
  };

  return (
    <li>
      <button
        className="bold-iq text-[17px] bg-transparent border-none"
        onClick={handleClick}
      >
        {language}
      </button>
    </li>
  );
}

Countries.propTypes = {
  /**
   * Languages
   */
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Country code
       */
      code: PropTypes.string.isRequired,
      /**
       * Language name
       */
      name: PropTypes.string.isRequired,
      /**
       * Image
       */
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
};
