"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import {
  useSelectShowMobileMenu,
  useDispatchSetShowMobileMenu,
  useSelectLocale,
} from "@/hooks";
import "@/app/[lang]/styles/style.css";

export default function ParentItem({
  text,
  link,
  linkId,
  linkClassName,
  setMobileMenuActiveClass,
  mobileMenuActiveClass,
}) {
  const showMobileMenu = useSelectShowMobileMenu();
  const dispatchSetShowMobileMenu = useDispatchSetShowMobileMenu();
  const ptext = text || "";
  const locale = useSelectLocale();

  return (
    <div
      className={
        "accordion-top " + (showMobileMenu ? mobileMenuActiveClass : "")
      }
      onClick={(e) => setMobileMenuActiveClass(e)}
    >
      <Link
        id={linkId || ""}
        className={"droplink " + (linkClassName || "")}
        href={link || "/link-here"}
        title={ptext}
        style={{ color: showMobileMenu ? "black" : "white" }}
        onClick={() => {
          if (showMobileMenu) dispatchSetShowMobileMenu(false);
        }}
      >
        <div
          className={`bold-iq !text-lg ${!showMobileMenu && "py-[23px] px-0"}`}
        >
          {ptext}
        </div>
      </Link>
      {showMobileMenu ? <i className="far fa-angle-down"></i> : null}
    </div>
  );
}
ParentItem.propTypes = {
  /**
   * Menu name
   */
  text: PropTypes.string.isRequired,
  /**
   * Menu link
   */
  link: PropTypes.string.isRequired,
  /**
   * Tag #id of the <a> element
   */
  linkId: PropTypes.string,
  /**
   * ClassName(s) of the <a> tag.
   */
  linkClassName: PropTypes.string,
  /**
   * Active class
   */
  mobileMenuActiveClass: PropTypes.string.isRequired,
  /**
   * Click event for showing menu items in mobile
   */
  setMobileMenuActiveClass: PropTypes.func.isRequired,
};
