"use client";
import { useEffect } from "react";

const FrameProtection = () => {
    useEffect(() => {
        if (self === top) {
            document.documentElement.style.display = "block";
        } else {
            top.location = self.location;
        }
    }, []);

    return null;
};

export default FrameProtection;
