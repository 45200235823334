"use client";
import Logo from "./logo";
import LoginMenu from "./login-menu";
import LoginMenuMobile from "./login-menu-mobile";
import Cart from "./cart";
import Nav from "./nav";

export default function Header({
  quick_links,
  menus,
  currencyList,
  locales,
  headerLogo,
  quick_links_auths,
  cart,
  greetingText,
  languageCurrencyTitle,
  csrfToken,
  loginUrl,
  my_account_menus,
  
}) {

  return (
    <header className="header" id="header">
      <div className="header__wrapper">
        <div className="container">
          <div className="header__inner">
            <Logo logo={headerLogo || ""} />
            <div className="header__inner--right desktop">
              <LoginMenu
                quick_links={quick_links || []}
                quick_links_auths={quick_links_auths || []}
                greetingText={greetingText || ""}
                csrfToken={csrfToken || ""}
                loginUrl={loginUrl || ""}
              />
              <Cart cart={cart || {}} />
            </div>
            <LoginMenuMobile
              button={quick_links?.[0]?.attributes?.link || {}}
            />
          </div>
        </div>
        <Nav
          menus={menus || []}
          currencyList={currencyList || []}
          locales={locales || []}
          quick_links={quick_links || []}
          languageCurrencyTitle={languageCurrencyTitle || ""}
          my_account_menus={my_account_menus || []}
        />
      </div>
    </header>
  );
}
