"use client";
import { useSelector } from "react-redux";

export default function Loader() {
    const showLoader = useSelector((state) => state.showLoader.value);

    return (
        <div
            className="loading-wrapper"
            style={{ display: showLoader ? "block" : "none" }}
        >
            <div className="loading" id="loader"></div>
        </div>
    );
}
