"use client";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { readyStoreSlice } from "./slices/readyStoreSlice";
import { localeSlice } from "./slices/localeSlice";
import { showMobileMenuSlice } from "./slices/showMobileMenuSlice";
import { showLoaderSlice } from "./slices/showLoaderSlice";
import { showToasterSlice } from "./slices/showToastSlice";
import { authUserSlice } from "./slices/authUserSlice";
import { counterSlice } from "./slices/counterSlice";
import { activeLineCardsSlice } from "./slices/activeLineCardsSlice";
import { newsSlice } from "./slices/selectedNewsSlice";
import { timeOutConfirmationSlice } from "./slices/timeOutConfirmationSlice";
import { selfExclusionConfirmationSlice } from "./slices/selfExclusionConfirmationSlice";
import { closeAccountConfirmationSlice } from "./slices/closeAccountConfirmationSlice";
import { balanceSlice } from "./slices/balanceSlice";
import { balanceTransactionSlice } from "./slices/balanceTransaction";
import { csrfTokenSlice } from "./slices/csrfTokenSlice";
import { cartSlice } from "./slices/cartSlice";
import { nonPassportUploadSlice } from "./slices/nonPassportUploadSlice";
import { grandDrawSlice } from "./slices/grandDrawSlice";
import { showFavouritesSlice } from "./slices/showFavouritesModalSlice";
import { addFavouritesSlice } from "./slices/addFavouritesModalSlice";
import { currencySlice } from "./slices/currencySlice";
import { paymentSlice } from "./slices/paymentSlice";
import { gameGroupsSlice } from "./slices/gameGroupsSlice";
import { geolocationSlice } from "./slices/geolocationSlice";

const rootReducer = combineReducers({
  [readyStoreSlice.name]: readyStoreSlice.reducer,
  [localeSlice.name]: localeSlice.reducer,
  [showMobileMenuSlice.name]: showMobileMenuSlice.reducer,
  [showLoaderSlice.name]: showLoaderSlice.reducer,
  [showToasterSlice.name]: showToasterSlice.reducer,
  [authUserSlice.name]: authUserSlice.reducer,
  [counterSlice.name]: counterSlice.reducer,
  [activeLineCardsSlice.name]: activeLineCardsSlice.reducer,
  [newsSlice.name]: newsSlice.reducer,
  [timeOutConfirmationSlice.name]: timeOutConfirmationSlice.reducer,
  [selfExclusionConfirmationSlice.name]: selfExclusionConfirmationSlice.reducer,
  [closeAccountConfirmationSlice.name]: closeAccountConfirmationSlice.reducer,
  [balanceSlice.name]: balanceSlice.reducer,
  [balanceTransactionSlice.name]: balanceTransactionSlice.reducer,
  [csrfTokenSlice.name]: csrfTokenSlice.reducer,
  [cartSlice.name]: cartSlice.reducer,
  [nonPassportUploadSlice.name]: nonPassportUploadSlice.reducer,
  [grandDrawSlice.name]: grandDrawSlice.reducer,
  [showFavouritesSlice.name]: showFavouritesSlice.reducer,
  [addFavouritesSlice.name]: addFavouritesSlice.reducer,
  [currencySlice.name]: currencySlice.reducer,
  [paymentSlice.name]: paymentSlice.reducer,
  [gameGroupsSlice.name]: gameGroupsSlice.reducer,
  [geolocationSlice.name]: geolocationSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);
