"use client";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import { addDaysFromToday } from "@/utils/functions.client";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useDispatchShowLoader,
  useSelectCsrfToken,
  useDispatchLogout,
  useRedirect,
  useSelectTimeOutConfirmation,
  useDispatchShowTimeOutConfirmation,
  useDispatchSetTimeOutConfirmationUpdateStatus,
} from "@/hooks";
import { DIGITS, STRING_CONSTANTS } from "@/utils/constants";
import {
  SUCCESS,
  FAILED,
  AUTHENTICATION_FAILED,
} from "@/utils/error-constants";
import { restrictionsEndpoint } from "@/services/client/endpoint";

export default function TimeOutConfirmation({ content = {} }) {
  const csrfToken = useSelectCsrfToken();
  const timeOutConfirmation = useSelectTimeOutConfirmation();
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchLogout = useDispatchLogout();
  const redirect = useRedirect();
  const dispatchShowTimeOutConfirmation = useDispatchShowTimeOutConfirmation();
  const dispatchSetTimeOutConfirmationUpdateStatus =
    useDispatchSetTimeOutConfirmationUpdateStatus();

  if (Object.keys(content).length < DIGITS?.ONE)
    content = timeOutConfirmation?.content || {};

  let confirmMsg = content?.timeOutConfirmMessage || "";
  confirmMsg = confirmMsg.replace(
    "{date}",
    `<span class="regular-iq">${addDaysFromToday(
      content?.timeOutPeriod?.value || DIGITS?.ZERO
    )}</span>`
  );
  confirmMsg = parser(confirmMsg);

  return (
    <div
      className={
        "remodal-wrapper remodal-is-opened " +
        (!!timeOutConfirmation?.isOpened ? "d-block" : "d-none")
      }
      onClick={(e) => {
        e.stopPropagation();
        dispatchShowTimeOutConfirmation(false);
      }}
    >
      <div
        className={
          "remodal modal modal--manage-account modal-time-out remodal-is-initialized" +
          (!!timeOutConfirmation?.isOpened
            ? " remodal-is-opened"
            : " remodal-is-closed")
        }
        data-remodal-id="modal-time-out"
        tabIndex="-1"
      >
        <button
          className="remodal-close"
          data-remodal-action="close"
          onClick={(e) => {
            e.preventDefault();
            dispatchShowTimeOutConfirmation(false);
          }}
        >
          <i className="far fa-times"></i>
        </button>
        <h2 className="modal__heading">{content?.selectText || ""}</h2>
        {confirmMsg}
        <form
          method="POST"
          className="timeout"
          onSubmit={async (e) => {
            e.preventDefault();
            let value = content?.timeOutPeriod?.value || DIGITS?.ONE;
            if (isNaN(value)) value = DIGITS?.ZERO;
            else value = parseInt(value);
            if (
              content?.otpCode?.length > DIGITS?.ZERO &&
              value >= DIGITS?.ONE
            ) {
              dispatchShowLoader(true);
              const response = await sendInternalPostRequest({
                endpoint: restrictionsEndpoint,
                data: {
                  type: STRING_CONSTANTS?.TIMEOUT,
                  token: content?.otpCode || "",
                  intervalType: STRING_CONSTANTS?.DAILY,
                  value,
                },
                csrfToken,
              });
              if (response?.success && !!response?.id) {
                dispatchSetTimeOutConfirmationUpdateStatus(SUCCESS);
              } else if (response?.message?.length > DIGITS?.ZERO) {
                const msg = response?.message?.toLowerCase() || FAILED;

                if (msg === AUTHENTICATION_FAILED?.toLowerCase() + "!") {
                  dispatchLogout();
                  redirect(content?.loginUrl || "/#");
                }

                dispatchSetTimeOutConfirmationUpdateStatus(SUCCESS); // msg
              }
              dispatchShowLoader(false);
              dispatchShowTimeOutConfirmation(false);
            }
          }}
        >
          <div className="form-group form-group__buttons">
            <button
              className="btn btn--secondary bold-iq"
              type="submit"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {content?.confirmButton?.text || ""}
            </button>
            <a
              className="btn btn--secondary-border bold-iq"
              href="#"
              data-remodal-action="close"
              onClick={(e) => {
                e.preventDefault();
                dispatchShowTimeOutConfirmation(false);
              }}
            >
              {content?.cancelButton?.text || ""}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}

TimeOutConfirmation.propTypes = {
  /**
   * Content data
   */
  content: PropTypes.object,
};
