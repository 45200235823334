"use client";
import PropTypes from "prop-types";
import FooterDesktop from "./desktop";
import FooterMobile from "./mobile";

export default function Footer({
  footerMenus,
  mobileMenus,
  socialLinks,
  contactInfo,
  footerLogo,
  copyright,
}) {
  return (
    <>
      <FooterMobile mobileMenus={mobileMenus || []} />
      <FooterDesktop
        footerMenus={footerMenus || []}
        socialLinks={socialLinks || {}}
        contactInfo={contactInfo || {}}
        footerLogo={footerLogo || ""}
        copyright={copyright || ""}
      />
    </>
  );
}

Footer.propTypes = {
  /**
   * Navigation menu items
   */
  footerMenus: PropTypes.array,
  /**
   * Navigation menu items for mobile
   */
  mobileMenus: PropTypes.array,
  /**
   * Footer social links
   */
  socialLinks: PropTypes.object,
  /**
   * Footer Contact info
   */
  contactInfo: PropTypes.object,
  /**
   * Footer Logo
   */
  footerLogo: PropTypes.string,
  /**
   * Copyright
   */
  copyright: PropTypes.string,
};
