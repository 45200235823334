"use client";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: {},
    results: [],
    pageNumber: 1,
    pageSize: 2,
    rowCount: 4
};

export const balanceTransactionSlice = createSlice({
    name: "balanceTransaction",
    initialState,
    reducers: {

        setResultSet(state, action) {
            state.results = action.payload;
        },
        setPageNum(state, action) {

            state.pageNumber = action.payload;


        },
        setPageSize(state, action) {
            state.pageSize = action.payload;
        },
        setRowCount(state, action) {
            state.rowCount = action.payload;
        },
    },
});

export default balanceTransactionSlice.reducer;

export const { setBalance, setResultSet, setPageNum, setPageSize, setRowCount } = balanceTransactionSlice.actions;


export const selectResultSet = (state) => state.balanceTransaction.results;
export const selectPageNum = (state) => state.balanceTransaction.pageNumber;
export const selectPageSize = (state) => state.balanceTransaction.pageSize;
export const selectRowCount = (state) => state.balanceTransaction.rowCount;
