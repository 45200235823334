import { useSelectLocale } from "@/hooks";
import Link from "next/link";
import PropTypes from "prop-types";
import "@/app/[lang]/styles/style.css";

export function ListLink({
  text = "list name here",
  listId = "",
  listClassName = "",
  link = "/link-here",
  linkId = "",
  linkClassName = "",
  color = "",
  onClickExtension = function () {},
}) {
  const locale = useSelectLocale();
  return (
    <li id={listId || ""} className={listClassName || ""}>
      <Link
        id={linkId || ""}
        href={link || "/link-here"}
        title={text || ""}
        className={`${linkClassName || ""} bold-iq !text-[17px] !text-${color}`}
        onClick={onClickExtension}
      >
        {text || ""}
      </Link>
    </li>
  );
}
ListLink.propTypes = {
  /**
   * List item name
   */
  text: PropTypes.string.isRequired,
  /**
   * Link relative path
   */
  link: PropTypes.string,
  /**
   * List id tag
   */
  listId: PropTypes.string,
  /**
   * List class names separeted by space
   */
  listClassName: PropTypes.string,
  /**
   * Link id tag
   */
  linkId: PropTypes.string,
  /**
   * Link class names separeted by space
   */
  linkClassName: PropTypes.string,
  /**
   * Link class names separeted by space
   */
  onClickExtension: PropTypes.func,
};
