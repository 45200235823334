"use client";
import Link from "next/link";
import PropTypes from "prop-types";
import { useDispatchSetShowMobileMenu, useSelectShowMobileMenu } from "@/hooks";

export default function LoginMenuMobile({ button = {} }) {
  const showMobileMenu = useSelectShowMobileMenu();
  const dispatchSetShowMobileMenu = useDispatchSetShowMobileMenu();

  return (
    <div className="header__inner--right mobile">
      <div
        className={
          "header__login-menu header__login-menu--mobile " +
          (showMobileMenu ? "active" : "")
        }
      >
        <div className="nav-wrapper">
          <nav></nav>
        </div>
      </div>

      <div className="header__button">
        <Link
          href={button?.link || "#button-link"}
          style={{
            color: "white",
            border: "1px solid",
            backgroundColor: "#fb7a74",
            padding: "5px 10px",
            borderRadius: "5px",
          }}
          onClick={(e) => {
            if (!!button?.isDisabled) {
              e.preventDefault();
              return;
            }
          }}
        >
          {button?.text || ""}
        </Link>
      </div>

      <div
        className={"header__toggle " + (showMobileMenu ? "active" : "")}
        onClick={() => dispatchSetShowMobileMenu(!showMobileMenu)}
      >
        <div className="one"></div>
        <div className="two"> </div>
      </div>
    </div>
  );
}

LoginMenuMobile.propTypes = {
  /**
   * Button
   */
  button: PropTypes.shape({
    /**
     * Text
     */
    text: PropTypes.string,
    /**
     * Link
     */
    link: PropTypes.string,
  }).isRequired,
};
