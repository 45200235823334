"use client";
import PropTypes from "prop-types";
import { store } from "./store";
import { Provider } from "react-redux";
import InitializeStore from "./initialize-store";

export default function ReduxProvider({
    children,
    lang = "en",
    gameGroupsCode = "",
}) {
    return (
        <Provider store={store}>
            <InitializeStore
                lang={lang || ""}
                gameGroupsCode={gameGroupsCode || ""}
            >
                {children}
            </InitializeStore>
        </Provider>
    );
}
ReduxProvider.propTypes = {
    /**
     * Child element nodes
     */
    children: PropTypes.node.isRequired,
    /**
     * Current language
     */
    lang: PropTypes.string.isRequired,
    /**
     * Game Group Code
     */
    gameGroupsCode: PropTypes.string.isRequired,
};
