"use client";
import PropTypes from "prop-types";
import FooterContact from "./contact";
import FooterFollow from "./follow";

export default function FooterSocials({ socialLinks, contactInfo }) {
    return (
        <div className="footer__socials">
            {/* <FooterContact contactInfo={contactInfo} /> */}
            <FooterFollow socialLinks={socialLinks} />
        </div>
    );
}

FooterSocials.propTypes = {
    /**
     * Footer social links
     */
    socialLinks: PropTypes.object,
    /**
     * Footer Contact info
     */
    contactInfo: PropTypes.object,
};
