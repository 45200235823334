"use client";
import PropTypes from "prop-types";
import { ListLink } from "@/utils/components.client";

export default function FooterMenu({ footerMenus = [] }) {
  if (typeof footerMenus !== typeof [] || footerMenus?.length < 1) return;

  return (
    <ul>
      {footerMenus.map(({ id, text, link, isDisabled }) =>
        isDisabled ? null : <ListLink key={id} text={text} link={link} />
      )}
    </ul>
  );
}

FooterMenu.propTypes = {
  /**
   * Navigation menu items
   */
  footerMenus: PropTypes.array,
};
