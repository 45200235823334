"use client";
import Image from "next/image";
import PropTypes from "prop-types";
import { useRouter, usePathname } from "next/navigation";
import {
  useDispatchBackToPagePath,
  useSelectAuthUser,
  useSelectCart,
  useSelectLocale,
} from "@/hooks";
import { formatNumber, generateUID } from "@/utils/functions.client";
import "./index.css";
import { DIGITS, LINKS } from "@/utils/constants";
import { Fragment } from "react";
export default function FooterMobileMenu({ mobileMenus = [] }) {
  const pathname = usePathname();
  const cartItems = useSelectCart();
  const locale = useSelectLocale();
  const authUser = useSelectAuthUser();
  const router = useRouter();
  const dispatchBackToLogin = useDispatchBackToPagePath();
  if (typeof mobileMenus !== typeof [] || mobileMenus?.length < DIGITS.ONE)
    return;

  let cartItemsCount = DIGITS.ZERO;

  if (Array.isArray(cartItems) && cartItems?.length > DIGITS.ZERO) {
    cartItems?.map((items) => {
      if (Array.isArray(items) && items?.length > DIGITS.ZERO) {
        cartItemsCount = cartItemsCount + items?.length;
      }
    });
  }

  const handleButtonClick = (link, showBadge, cartItemsCount, cartLink) => {
    const addCreditLink = `/${locale}` + LINKS.ADD_CREDIT_LINK;
    const balanceSummaryLink = `/${locale}` + LINKS.BALANCE_SUMMARY;
    const myPurchaseLink = `/${locale}` + LINKS.MY_PURCHASE;
    const hrefLink =
      showBadge && cartItemsCount > DIGITS.ZERO && cartLink ? cartLink : link;

    const specialLinks = [addCreditLink, balanceSummaryLink, myPurchaseLink];

    if (specialLinks.includes(link)) {
      if (authUser?.profile?.dateOfBirth) {
        router.push(link);
      } else {
        dispatchBackToLogin(link);
        router.push(`/${locale}` + LINKS.LOGIN);
      }
    } else {
      router.push(hrefLink);
    }
  };

  return (
    <ul>
      {mobileMenus.map(
        ({
          id,
          link,
          event,
          text,
          image,
          isDisabled,
          image_selected,
          cartLink,
          showBadge,
        }) => {
          return (
            <Fragment key={generateUID()}>
              {isDisabled ? null : (
                <li>
                  <button
                    className="border-none bg-white"
                    onClick={() => {
                      handleButtonClick(
                        link,
                        showBadge,
                        cartItemsCount,
                        cartLink
                      );
                    }}
                  >
                    <div className="footer-mobile__item">
                      <div className="footer-mobile__item--icon">
                        {showBadge && cartItemsCount >= DIGITS.ONE ? (
                          <div
                            className={`${"digits-iq-bld footer-mobile__item--count"}`}
                          >
                            {formatNumber(cartItemsCount)}
                          </div>
                        ) : null}
                        <Image
                          src={
                            link === pathname || cartLink === pathname
                              ? image_selected
                              : image
                          }
                          alt="footer_icons"
                          width="35"
                          height="35"
                        />
                      </div>
                      <span className="span-footer-text bold-iq">{text}</span>
                    </div>
                  </button>
                </li>
              )}
            </Fragment>
          );
        }
      )}
    </ul>
  );
}

FooterMobileMenu.propTypes = {
  /**
   * Navigation menu items for mobile
   */
  mobileMenus: PropTypes.array,
};
