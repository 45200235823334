"use client";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useDispatchShowLoader,
  useSelectCsrfToken,
  useDispatchLogout,
  useRedirect,
  useSelectCloseAccountConfirmation,
  useDispatchShowCloseAccountConfirmation,
  useDispatchSetCloseAccountConfirmationUpdateStatus,
  useSelectAuthUser,
} from "@/hooks";
import {
  SUCCESS,
  FAILED,
  AUTHENTICATION_FAILED,
} from "@/utils/error-constants";
import { DIGITS } from "@/utils/constants";

export default function CloseAccountConfirmation({ content = {} }) {
  const closeAccountConfirmation = useSelectCloseAccountConfirmation();
  const csrfToken = useSelectCsrfToken();
  const dispatchShowLoader = useDispatchShowLoader();
  const dispatchLogout = useDispatchLogout();
  const dispatchShowCloseAccountConfirmation =
    useDispatchShowCloseAccountConfirmation();
  const dispatchSetCloseAccountConfirmationUpdateStatus =
    useDispatchSetCloseAccountConfirmationUpdateStatus();
  const redirect = useRedirect();
  const authUser = useSelectAuthUser();

  if (Object.keys(content).length < DIGITS?.ONE)
    content = closeAccountConfirmation?.content || {};

  return (
    <div
      className={
        "remodal-wrapper remodal-is-opened " +
        (!!closeAccountConfirmation?.isOpened ? "d-block" : "d-none")
      }
      onClick={(e) => {
        e.stopPropagation();
        dispatchShowCloseAccountConfirmation(false);
      }}
    >
      <div
        className={
          "remodal modal modal--manage-account modal-close-account remodal-is-initialized" +
          (!!closeAccountConfirmation?.isOpened
            ? " remodal-is-opened"
            : " remodal-is-closed")
        }
        data-remodal-id="modal-close-account"
        tabIndex="-1"
      >
        <button
          className="remodal-close"
          data-remodal-action="close"
          onClick={(e) => {
            e.preventDefault();
            dispatchShowCloseAccountConfirmation(false);
          }}
        >
          <i className="far fa-times"></i>
        </button>

        <h2 className="modal__heading">
          {content?.closeAccountConfirmTitle || ""}
        </h2>
        {parser(content?.closeAccountConfirmMessage || "")}
        <form
          method="POST"
          className="closeAccount"
          onSubmit={async (e) => {
            e.preventDefault();
            let value = content?.closeAccountReason?.value || DIGITS?.FOUR;
            if (isNaN(value)) value = DIGITS?.FOUR;
            else value = parseInt(value);
            if (!!content?.otpCode && value >= DIGITS?.ONE) {
              dispatchShowLoader(true);
              const response = await sendInternalPostRequest({
                endpoint: "/api/restrictions",
                data: {
                  type: "AccountDeactivation",
                  token: content?.otpCode || "",
                  intervalType: "",
                  value,
                  email: authUser?.profile?.email || "",
                  firstName: authUser?.profile?.firstName || "",
                },
                csrfToken,
              });
              const msg = response?.message.toLowerCase() || FAILED;
              dispatchShowLoader(false);
              dispatchShowCloseAccountConfirmation(false);
              if (
                (response?.success && !!response?.id) ||
                msg === AUTHENTICATION_FAILED + "!"
              ) {
                dispatchLogout();
                const logout = await sendInternalPostRequest({
                  endpoint: "/api/logout",
                  csrfToken,
                });
                redirect(content?.loginUrl || "/#");
              } else if (!!response?.message) {
                dispatchSetCloseAccountConfirmationUpdateStatus(SUCCESS); // msg
              }
            }
          }}
        >
          <div className="form-group form-group__buttons">
            <button
              className="btn btn--secondary bold-iq"
              type="submit"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {content?.confirmButton?.text || ""}
            </button>
            <a
              className="btn btn--secondary-border bold-iq"
              href="#"
              data-remodal-action="close"
              onClick={(e) => {
                e.preventDefault();
                dispatchShowCloseAccountConfirmation(false);
              }}
            >
              {content?.cancelButton?.text || ""}
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}

CloseAccountConfirmation.propTypes = {
  /**
   * Content data
   */
  content: PropTypes.object,
};
