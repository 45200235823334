"use client";
import Link from "next/link";
import Image from "next/image";
import PropTypes from "prop-types";
import { getTotalCartAmount } from "@/utils/functions.client";
import {
  useSelectCurrency,
  useSelectGrandDrawPrice,
  useSelectGameGroups,
  useSelectLocale,
} from "@/hooks";
import { splitWithCommasForInteger } from "@/utils/functions.client";
import { DIGITS } from "@/utils/constants";
import "@/app/[lang]/styles/style.css";

export default function CartContent({ cart = {}, cartItems = [] }) {
  const currency_User = useSelectCurrency();
  const price = useSelectGrandDrawPrice();
  const gameGroups = useSelectGameGroups();
  const locale = useSelectLocale();

  const totalCartAmount = getTotalCartAmount(
    cartItems,
    gameGroups?.[DIGITS.ZERO]?.gamePrice?.amount ||
      currency_User?.amount ||
      price?.amount ||
      DIGITS.ZERO
  );
  let cartItemsCount = 0;
  const { cartUrl, buy_cta_url, cartImage, cartLabel, currency } = cart;
  if (Array.isArray(cartItems) && cartItems?.length > DIGITS.ZERO) {
    cartItems?.map((items) => {
      if (Array.isArray(items) && items?.length > DIGITS.ZERO) {
        cartItemsCount = cartItemsCount + items?.length;
      }
    });
  }

  const href =
    Array.isArray(cartItems) && cartItems.length > DIGITS.ZERO
      ? cartUrl
      : buy_cta_url;

  return (
    <Link href={href || ""}>
      <div className="header__cart--content">
        <div className="header__cart--icon">
          <Image
            src={cartImage || ""}
            alt="Cart"
            style={{ width: "auto", height: "auto" }}
            width={24}
            height={24}
            loading="lazy"
          />
          <div className="header__cart--count">
            <span className="digits-iq-bld">{cartItemsCount}</span>
          </div>
        </div>
        <div className="header__cart--info">
          <p className="bold-iq !text-brown">{cartLabel || ""}</p>
          <div
            className={`price-wrapp ${
              locale === "ar" ? "dir-ltr" : "flex-row"
            }`}
          >
            <span
              className={`bold-iq !text-brown ${
                locale === "ar" ? "px-1 font-500" : ""
              }`}
            >
              {currency || ""}
            </span>{" "}
            <span className="cartsum digits-iq-bld !text-brown">
              {splitWithCommasForInteger(Math.floor(totalCartAmount))}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
}

CartContent.propTypes = {
  /**
   * Cart info
   */
  cart: PropTypes.object.isRequired,
  /**
   * Cart items
   */
  cartItems: PropTypes.array.isRequired,
};
