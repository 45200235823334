"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import { useState } from "react";
import { ListLink } from "@/utils/components.client";
import { generateUID } from "@/utils/functions.client";
import {
  useSelectShowMobileMenu,
  useDispatchSetShowMobileMenu,
  useSelectLocale,
} from "@/hooks";
import "@/app/[lang]/styles/style.css";

export default function ChildItem({
  text,
  link,
  linkId,
  linkClassName,
  subMenu = [],
}) {
  const showMobileMenu = useSelectShowMobileMenu();
  const dispatchSetShowMobileMenu = useDispatchSetShowMobileMenu();
  const [mobileMenuActiveClass, setMobileMenuActiveClass] = useState("");
  const locale = useSelectLocale();
  const ptext = text || "";
  const childItem =
    !!subMenu && subMenu.length > 0 ? (
      <li className="accordion-nested">
        <div
          className={"accordion-top " + mobileMenuActiveClass}
          onClick={() => {
            if (showMobileMenu) {
              setMobileMenuActiveClass(!!mobileMenuActiveClass ? "" : "active");
            }
          }}
        >
          <Link
            href={link || ""}
            title={ptext}
            onClick={() => {
              if (showMobileMenu) dispatchSetShowMobileMenu(false);
            }}
            className="bold-iq !text-[17px]"
          >
            {ptext}
          </Link>
          <i className="far fa-angle-down text-[#fb9a63]"></i>
        </div>
        <ul
          className={
            "accordion-content accordion-dropdown-second " +
            (mobileMenuActiveClass || "")
          }
          style={{
            display:
              !!mobileMenuActiveClass && showMobileMenu
                ? "block"
                : showMobileMenu
                ? "none"
                : "",
          }}
        >
          {subMenu.map(
            ({ text, link, linkId, linkClassName, subMenu, isDisabled }) =>
              isDisabled ? null : (
                <ChildItem
                  key={generateUID()}
                  linkId={linkId || ""}
                  text={text || ""}
                  link={link || ""}
                  linkClassName={linkClassName || ""}
                  subMenu={subMenu}
                />
              )
          )}
        </ul>
      </li>
    ) : (
      <ListLink
        key={generateUID()}
        linkId={linkId || ""}
        text={ptext}
        link={link}
        linkClassName={linkClassName || ""}
        onClickExtension={() => {
          if (showMobileMenu) dispatchSetShowMobileMenu(false);
        }}
      />
    );

  return <>{childItem}</>;
}
ChildItem.propTypes = {
  /**
   * Menu name
   */
  text: PropTypes.string.isRequired,
  /**
   * Menu link
   */
  link: PropTypes.string.isRequired,
  /**
   * Tag #id of the <a> element
   */
  linkId: PropTypes.string,
  /**
   * ClassName(s) of the <a> tag.
   */
  linkClassName: PropTypes.string,
  /**
   * Sub menu items
   */
  subMenu: PropTypes.array,
};
