"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import { useSelectBalance, useSelectCurrency, useSelectLocale } from "@/hooks";
import { splitWithComas } from "@/utils/functions.client";
import { trackBuynowEvent, trackClickEvent } from "@/utils/gtm";
import { useSelector } from "react-redux";
export default function BalanceSummary({ content = {}, csrfToken = "" }) {
  const balanceInfo = useSelectBalance();

  const locale = useSelectLocale();
  const currency = useSelectCurrency();
  let currencyCode =
    currency?.currencyCode || balanceInfo?.currencyCode || "IQD";
  if (currencyCode?.toUpperCase() === "IQD" && locale === "ar")
    currencyCode = "دينار";
  const gtmData = useSelector((state) => state?.authUser?.data);
  const gtmEvent = () => {
    trackClickEvent(
      "e_headerinteraction",
      "non_ecommerce",
      "withdraw",
      "",
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };
  const gtmbuynowEvent = () => {
    trackBuynowEvent(
      "e_headerinteraction",
      "non_ecommerce",
      "buy_now",
      "millionaire",
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };
  return (
    <div className="header__dropdown secondary">
      <div className="header__balance">
        <div className="header__balance--top">
          <p>{content?.attributes?.subItems?.[0]?.text || ""}</p>
          <span className="digits-iq-bld dir-ltr align-items-center">
            <span className="bold-iq font-semibold px-1">
              {currencyCode}
            </span>
            {splitWithComas(
              (balanceInfo?.credit + balanceInfo?.winnings || 0).toFixed(2)
            )}
          </span>
        </div>
        <div className="header__balance--item">
          <div className="header__balance--row">
            <Link
              className="btn btn--primary bold-iq"
              href={
                content?.attributes?.subItems?.[4]?.link || "#transfer-withdraw"
              }
              style={{
                width: "53%",
                background: "#fb7a74",
                marginLeft: locale == "en" ? 0 : "10px",
                fontSize: "13px",
                color: "#fff",
              }}
              onClick={(e) => {
                gtmEvent();
                if (!!content?.attributes?.subItems?.[4]?.isDisabled) {
                  e.preventDefault();
                  return;
                }
              }}
            >
              {content?.attributes?.subItems?.[4]?.text || ""}
            </Link>
            <Link
              className="btn btn--primary bold-iq"
              href={content?.attributes?.subItems?.[2]?.link || "#play"}
              style={{
                width: "47%",
                marginLeft: "10px",
                background: "#fb7a74",
                fontSize: "13px",
                color: "#fff",
              }}
              onClick={(e) => {
                gtmbuynowEvent();
                if (!!content?.attributes?.subItems?.[2]?.isDisabled) {
                  e.preventDefault();
                  return;
                }
              }}
            >
              {content?.attributes?.subItems?.[2]?.text || ""}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

BalanceSummary.propTypes = {
  /**
   * Content data
   */
  content: PropTypes.object.isRequired,
  /**
   * CSRF Token
   */
  csrfToken: PropTypes.string,
};
