"use client";
import axios from "axios";

export default async function sendCsrfTokenRequest() {
    const response = await axios
        .get("/api/csrf-token", {})
        .then((res) => res?.data || {})
        .catch((error) => {
            if (error?.response?.status >= 500) {
                throw new Error("Failed to send get csrf token request.");
            }
            return error?.response?.data || {};
        });

    return response;
}
