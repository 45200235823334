"use client";
import PropTypes from "prop-types";
import Link from "next/link";
import { useSelectLocale } from "@/hooks";
import { trackClickEvent } from "@/utils/gtm";
import { useSelector } from "react-redux";

export default function Logo({ logo = "" }) {
  const locale = useSelectLocale();
  const gtmData = useSelector((state) => state?.authUser?.data);

  const GTMEvents = () => {
    trackClickEvent(
      "e_headerinteraction",
      "non_ecommerce",
      "logo_click",
      "iqm logo",
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };

  return (
    <Link href={"/" + (locale || "")} aria-label="Header_Logo" role="button">
      <div
        className="header__inner--logo"
        style={{ backgroundImage: `url(${logo})` }} // Using template literal for better readability
        onClick={GTMEvents}
        aria-label="Header_Logo" // Correctly set aria-label
        role="button" // Correctly set role
      ></div>
    </Link>
  );
}

Logo.propTypes = {
  /**
   * Logo URL
   */
  logo: PropTypes.string.isRequired,
};
