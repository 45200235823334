"use client";
import PropTypes from "prop-types";
import CartContent from "./cart-content";
import CartAlert from "./cart-alert";
import { useSelectCart } from "@/hooks";
import { DIGITS } from "@/utils/constants";

export default function Cart({ cart = {} }) {
  const cartItems = useSelectCart();
  return (
    <div className="header__cart">
      <CartContent cart={cart} cartItems={cartItems || []} />
      {cartItems?.length < DIGITS.ONE ? <CartAlert cart={cart} /> : ""}
    </div>
  );
}

Cart.propTypes = {
  /**
   * Cart info
   */
  cart: PropTypes.object.isRequired,
};
