"use client";
import Link from "next/link";
import Image from "next/image";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { trackClickEvent } from "@/utils/gtm";

export default function FooterFollow({ socialLinks }) {
  const gtmData = useSelector((state) => state?.authUser?.data);

  const handleClick = (title) => {
    trackClickEvent(
      "e_footerinteraction",
      "non_ecommerce",
      "social_handle",
      title?.toLowerCase(),
      gtmData?.lang || "en",
      gtmData?.currentPage || "",
      gtmData?.category || "",
      gtmData?.subcategory || "",
      gtmData?.previousPage || "",
      gtmData?.loggedIn || "",
      gtmData?.userid || ""
    );
  };

  return (
    <div className="footer__socials--item">
      <p
        className="bold-iq"
      >
        {socialLinks?.call_to_action || ""}
      </p>
      <div className="footer__socials--platforms">
        {typeof socialLinks?.socialIcons === typeof [] &&
          socialLinks.socialIcons.map(({ id, image,name, link, imageUrl }) => {
            let icon = null;
            if (!!imageUrl) {
              icon = (
                <Image
                  key={id}
                  src={imageUrl}
                  height="35"
                  width="35"
                  alt={image || ""}
                  loading="lazy"
                  style={{
                    width: "35px",
                    height: "35px",
                  }}
                />
              );
            } else if (!!image && typeof image === "string") {
              if (image.includes("fab") && image.includes("fa-")) {
                icon = <i key={id} className={image} />;
              } else if (
                image.includes("https://") ||
                image.includes("http://")
              ) {
                icon = (
                  <Image
                    key={id}
                    src={image}
                    height="19"
                    width="16"
                    alt={image}
                    loading="lazy"
                  />
                );
              } else {
                icon = (
                  <svg
                    key={id}
                    xmlns="http://www.w3.org/2000/svg"
                    height="26px"
                    width="26px"
                    viewBox="0 0 512 512"
                  >
                    {/* Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                    <path style={{ fill: "#0078f5" }} d={image} />
                  </svg>
                );
              }
            }

            return (
              <p onClick={() => handleClick(name)} key={id}>
                <Link href={link || ""} target="_blank" aria-label="social-icons">
                  {icon}
                </Link>
              </p>
            );
          })}
      </div>
    </div>
  );
}

FooterFollow.propTypes = {
  /**
   * Footer social links
   */
  socialLinks: PropTypes.object,
};
