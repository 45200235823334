export const getPageGTMData = (pathName) => {
  const pageMapping = {
    en: { category: "home", subcategory: "", pagename: "homepage" },
    ar: { category: "home", subcategory: "", pagename: "homepage" },
    participate: {
      category: "participate",
      subcategory: "buy now",
      pagename: "participatepage",
    },
    "prize-structure": {
      category: "participate",
      subcategory: "prize-structure",
      pagename: "prize structure page",
    },
    "find-stores": {
      category: "participate",
      subcategory: "stores",
      pagename: "store page",
    },
    "how-to-participate": {
      category: "participate",
      subcategory: "how-to-participate",
      pagename: "how to participate page",
    },
    "how-to-buy-credit": {
      category: "participate",
      subcategory: "how-to-add-credit",
      pagename: "how to add credit page",
    },
    "how-to-withdraw": {
      category: "participate",
      subcategory: "how-to-withdraw",
      pagename: "how to withdraw page",
    },
    faqs: {
      category: "participate",
      subcategory: "faqs",
      pagename: "faq page",
    },
    draw: { category: "draw", subcategory: "", pagename: "draw page" },
    "live-draw-show": {
      category: "draw",
      subcategory: "live-draw-show",
      pagename: "live draw show page",
    },
    "previous-draw-results": {
      category: "draw",
      subcategory: "previous-draw-results",
      pagename: "previous draw results page",
    },
    "past-draw-shows": {
      category: "draw",
      subcategory: "past-draw-shows",
      pagename: "past draw shows page",
    },
    pictures: {
      category: "draw",
      subcategory: "winners/pictures",
      pagename: "winners picture page",
    },
    stories: {
      category: "draw",
      subcategory: "winners/stories",
      pagename: "winners stories page",
    },
    responsibilities: {
      category: "responsibilities",
      subcategory: "responsibilities",
      pagename: "responsibilities page",
    },
    "fraud-awareness": {
      category: "responsibilities",
      subcategory: "fraud-awareness",
      pagename: "fraud awareness page",
    },
    "participate-consciously": {
      category: "responsibilities",
      subcategory: "participate-consciously",
      pagename: "participate consciously page",
    },
    "about-us": {
      category: "about-us",
      subcategory: "",
      pagename: "aboutus page",
    },
    legal: {
      category: "legal",
      subcategory: "",
      pagename: "legal page",
    },
    "terms-and-conditions": {
      category: "legal",
      subcategory: "terms-and-conditions",
      pagename: "terms & conditions page",
    },
    "social-media-community-guidelines": {
      category: "legal",
      subcategory: "social-media-community-guidelines",
      pagename: "social media community guidelines page",
    },
    disclaimer: {
      category: "legal",
      subcategory: "disclaimer",
      pagename: "disclaimer page",
    },
    "privacy-policy": {
      category: "legal",
      subcategory: "privacy-policy",
      pagename: "privacy policy page",
    },
    "cookies-policy": {
      category: "legal",
      subcategory: "cookies-policy",
      pagename: "cookies policy page",
    },
    "contact-us": {
      category: "contact-us",
      subcategory: "",
      pagename: "contact us page",
    },
    "a-new-era-has-started-millionaire-brings-life-changing-weekly-opportunities-to-iraq":
      {
        category: "latest news",
        subcategory:
          "a-new-era-has-started-millionaire-brings-life-changing-weekly-opportunities-to-iraq",
        pagename: "A new era has started page",
      },
    "latest-news": {
      category: "latest-news",
      subcategory: "",
      pagename: "latest news page",
    },
    "add-credit": {
      category: "My Account",
      subcategory: "add-credit",
      pagename: "add credit page",
    },
    withdraw: {
      category: "My Account",
      subcategory: "withdraw",
      pagename: "withdraw page",
    },
    "balance-summary": {
      category: "My Account",
      subcategory: "balance-summary",
      pagename: "balance summary page",
    },
    history: {
      category: "My Account",
      subcategory: "history",
      pagename: "purchase history page",
    },
    "my-profile": {
      category: "My Account",
      subcategory: "my-profile",
      pagename: "profile page",
    },
    "change-password": {
      category: "My Account",
      subcategory: "change-password",
      pagename: "change password page",
    },
    "manage-account": {
      category: "My Account",
      subcategory: "manage-account",
      pagename: "manage account page",
    },

    "favourite-numbers": {
      category: "My Account",
      subcategory: "favourite-numbers",
      pagename: "favourite numbers page",
    },
    login: {
      category: "Login",
      subcategory: "",
      pagename: "login page",
    },
    "create-account": {
      category: "Create Account",
      subcategory: "",
      pagename: "create account page",
    },
    "add-credit-payment-success": {
      category: "Add Credit",
      subcategory: "",
      pagename: "add credit payment success page",
    },
    "add-credit-payment-failure": {
      category: "Add Credit",
      subcategory: "",
      pagename: "add credit payment failure page",
    },
  };

  return (
    pageMapping[pathName] || { category: "", subcategory: "", pagename: "" }
  );
};
