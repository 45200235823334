"use client";
import PropTypes from "prop-types";
import { useEffect } from "react";
import sendCsrfTokenRequest from "@/services/client/sendGetCsrfTokenRequest";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
    useSelectCsrfToken,
    useSelectReadyStore,
    useDispatchSetLocale,
    useDispatchSetCsrfToken,
    useDispatchSetCart,
    useDispatchSetGrandDraw,
    useDispatchSetDrawID,
    useDispatchSetAuthUser,
    useDispatchSetBalance,
    useDispatchSetCounterDetails,
    useDispatchSetReadyStore,
    useDispatchSetGameGroups,
} from "@/hooks";
import { setSubGameGroupId } from "@/redux/slices/grandDrawSlice";
import { useDispatch } from "react-redux";
import { DIGITS } from "@/utils/constants";

export default function InitializeStore({
    children,
    lang = "en",
    gameGroupsCode = "",
}) {
    const readyStore = useSelectReadyStore();
    const csrfToken = useSelectCsrfToken();
    const dispatchSetLocale = useDispatchSetLocale();
    const dispatchSetCsrfToken = useDispatchSetCsrfToken();
    const dispatchSetCart = useDispatchSetCart();
    const dispatchSetGrandDraw = useDispatchSetGrandDraw();
    const dispatchSetDrawID = useDispatchSetDrawID();
    const dispatchSetAuthUser = useDispatchSetAuthUser();
    const dispatchSetBalance = useDispatchSetBalance();
    const dispatchSetCounterDetails = useDispatchSetCounterDetails();
    const dispatchSetReadyStore = useDispatchSetReadyStore();
    const dispatchSetGameGroups = useDispatchSetGameGroups();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!readyStore) {
            dispatchSetLocale(lang);
        }
    }, [lang, dispatchSetLocale, readyStore]);

    useEffect(() => {
        async function getCsrfToken() {
            const response = await sendCsrfTokenRequest();
            if (response?.success && !!response?.token) {
                await dispatchSetCsrfToken(response?.token);
            }
        }

        getCsrfToken();
    }, [dispatchSetCsrfToken]);

    useEffect(() => {
        let gameGroupId = 0;

        async function getGameGroups() {
            const groupsCode = gameGroupsCode?.split(",") || [];

            if (Array.isArray(groupsCode)) {
                const response = await sendInternalPostRequest({
                    endpoint: "/api/games/groups",
                    csrfToken,
                });

                if (response?.success && Array.isArray(response?.groups)) {
                    const filteredGroups = response?.groups?.filter(
                        (group) =>
                            group.parentGameGroupID === DIGITS.HUNDRED_TWO
                    );
                    const subgameGroupId = filteredGroups[0]?.gameGroupID;
                    dispatch(setSubGameGroupId(subgameGroupId));
                    let gameGroups = [];
                    let index = 0;
                    for (const group of response.groups) {
                        if (groupsCode?.includes(group?.code || "")) {
                            const response = await sendInternalPostRequest({
                                endpoint: "/api/games/group/information",
                                csrfToken,
                                data: { groupId: group?.gameGroupID || 0 },
                            });

                            if (response?.group?.gameGroupID) {
                                gameGroups.push({
                                    ...response?.group,
                                    gameGroupCode: group.code,
                                });

                                if (index === 0) {
                                    gameGroupId = response.group.gameGroupID;
                                }
                                index++;
                            }
                        }
                    }

                    dispatchSetGameGroups([...gameGroups]);
                }
            }
        }

        async function getCartItems() {
            const result = await sendInternalPostRequest({
                endpoint: "/api/cart",
                csrfToken,
                data: { gameGroupId },
            });
            if (result?.success && Array.isArray(result?.cart)) {
                dispatchSetCart([...result.cart]);
                const drawsResult = await sendInternalPostRequest({
                    endpoint: "/api/draws",
                    csrfToken,
                    data: { gameGroupId },
                });

                if (
                    drawsResult?.success &&
                    Array.isArray(drawsResult?.draws) &&
                    drawsResult?.draws?.length > 0
                ) {
                    const draw = drawsResult?.draws[0] || {};

                    await dispatchSetGrandDraw({
                        prizes: draw?.gameTiers[0]?.prizeTiers || [],
                        price: draw?.gameTiers[0]?.ticketPrice || {},
                        gameId: draw?.gameTiers[0]?.gameID || 0,
                        salesEnd: draw?.salesEnd || "",
                        drawDate: draw?.drawDate || "",
                    });

                    await dispatchSetDrawID(draw?.drawID);
                    await dispatchSetCounterDetails(draw?.drawDate || "");
                }
            }
        }

        async function getAuthUser() {
            const response = await sendInternalPostRequest({
                endpoint: "/api/auth-user",
                csrfToken,
            });
            if (
                response?.success &&
                !!response?.authUser?.profile?.dateOfBirth
            ) {
                dispatchSetAuthUser(response?.authUser);
            }
        }

        async function getBalance() {
            const response = await sendInternalPostRequest({
                endpoint: "/api/balance",
                csrfToken,
            });
            if (response?.success && !!response?.balance?.currencyCode) {
                dispatchSetBalance({ ...response.balance });
            }
        }

        if (!readyStore && !!csrfToken) {
            async function initialize() {
                await getGameGroups();
                await getCartItems();
                await getAuthUser();
                await getBalance();
                await dispatchSetReadyStore(true);
            }
            initialize();
        }
    }, [
        csrfToken,
        readyStore,
        gameGroupsCode,
        dispatchSetGameGroups,
        dispatchSetCart,
        dispatchSetGrandDraw,
        dispatchSetDrawID,
        dispatchSetAuthUser,
        dispatchSetBalance,
        dispatchSetCounterDetails,
        dispatchSetReadyStore,
        dispatch,
    ]);

    return <>{children}</>;
}
InitializeStore.propTypes = {
    /**
     * Child element nodes
     */
    children: PropTypes.node.isRequired,
    /**
     * Current language
     */
    lang: PropTypes.string.isRequired,
    /**
     * Game Groups Code
     */
    gameGroupsCode: PropTypes.string.isRequired,
};
