"use client";
import TimeOutConfirmation from "@/components/my-account/manage-account/time-out/confirmation";
import SelfExclusionConfirmation from "@/components/my-account/manage-account/self-exclusion/confirmation";
import CloseAccountConfirmation from "@/components/my-account/manage-account/close-account/confirmation";
import {
    useSelectAuthUser,
    useSelectTimeOutConfirmation,
    useSelectSelfExclusionConfirmation,
    useSelectCloseAccountConfirmation,
} from "@/hooks";

export default function ManageAccountConfirmations({}) {
    const authUser = useSelectAuthUser();
    const timeOutConfirmation = useSelectTimeOutConfirmation();
    const selfExclusionConfirmation = useSelectSelfExclusionConfirmation();
    const closeAccountConfirmation = useSelectCloseAccountConfirmation();

    if (!authUser?.profile?.dateOfBirth) return;

    return (
        <>
            <div
                className={
                    "remodal-overlay" +
                    (!!timeOutConfirmation?.isOpened ||
                    !!selfExclusionConfirmation?.isOpened ||
                    !!closeAccountConfirmation?.isOpened
                        ? " remodal-is-opened d-block"
                        : " remodal-is-closed d-none")
                }
            ></div>
            <TimeOutConfirmation content={timeOutConfirmation?.content || {}} />
            <SelfExclusionConfirmation
                content={selfExclusionConfirmation?.content || {}}
            />
            <CloseAccountConfirmation
                content={closeAccountConfirmation?.content || {}}
            />
        </>
    );
}
