"use client";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "next/navigation";
import {
    resetFavouriteNumbersValues,
    selectShowFavouriteCardIndex,
    selectShowFavourites,
    selectShowFavouritesNumbers,
    setShowFavouritesIsOpened,
} from "@/redux/slices/showFavouritesModalSlice";
import { selectAuthUser } from "@/redux/slices/authUserSlice";
import { generateUID, sortLineCardValues } from "@/utils/functions.client";
import { DIGITS } from "@/utils/constants";
import { useDispatchSetActiveCardFav, useDispatchSetActiveLineCardValues } from "@/hooks";
import "./index.css"
export default function ShowFavouritesModal({ content = {} }) {
    const showFavourites = useSelector(selectShowFavourites);
    const authUser = useSelector(selectAuthUser);
    const favouriteNumbers = useSelector(selectShowFavouritesNumbers);
    const favouriteCardIndex = useSelector(selectShowFavouriteCardIndex);

    const dispatchSetActiveLineCardValues = useDispatchSetActiveLineCardValues();
    const dispatchSetActiveCardFav = useDispatchSetActiveCardFav();

    const dispatch = useDispatch();
    const searchParams = useSearchParams();
    const totalCombination = DIGITS?.FOUR;
    const oneVal = DIGITS.ONE

    const list = [];

    const selectFavourites = (e,numbers) => {
        e.stopPropagation();
        const resVal = sortLineCardValues(numbers.numbers.split(',').map(Number));
          if (searchParams?.has("item")) {
            dispatchSetActiveCardFav({
                index: favouriteCardIndex - oneVal,
                values: resVal,
                totalCombination
            });
          }
          else {
            dispatchSetActiveLineCardValues({
                index: favouriteCardIndex - oneVal,
                values: resVal,
                totalCombination
              });
          }

        dispatch(setShowFavouritesIsOpened({value: false}));
    };

    const closeModal = (e) => {
        e.stopPropagation();
        dispatch(setShowFavouritesIsOpened({value: false}));
        dispatch(resetFavouriteNumbersValues());
    }

    if (!authUser?.profile?.dateOfBirth) return;

    if (Object.keys(content).length < 1)
        content = showFavourites?.content || {};

    if (Array.isArray(favouriteNumbers) && favouriteNumbers?.length > 0) {
        favouriteNumbers?.map((numbers, index) => {
            list.push(
                <div
                    key={"favNum" + index + generateUID()}
                    className="remodal__numberlist--number"
                    onClick={(e) => selectFavourites(e,numbers)}
                >
                    <p className="digits-iq">{numbers?.displayName || ""}</p>
                </div>
            );
        });
    }

    return (
        <>
          <div className={`remodal-overlay ${showFavourites?.isOpened ? 'is-open' : 'is-closed'}`}/>

        <div className={`remodal-wrapper ${showFavourites?.isOpened ? 'is-open' : 'is-closed'}`} onClick={(e) => closeModal(e)}>
            <div className={`remodal modal modal--fav-numbers remodal-is-initialized ${showFavourites?.isOpened ? 'is-open' : 'is-closed'}`}
                data-remodal-id="modal-verification" tabIndex="-1">
                <button
                    className="remodal-close"
                    data-remodal-action="close"
                    onClick={(e) => closeModal(e)}
                >
                    <i className="fal fa-times" />
                </button>
                <h2 className="modal__heading">{content?.title || ""}</h2>
                {!list?.length > 0 ? <h3 className="modal__heading modal__heading_no-fav-number font-500">
                    {content?.emptyFavouritesRemarks || ""}
                </h3> :""}
                
                <div className="remodal__numberlist" id="fav-numbers-list">
                    {list}
                </div>
            </div>
        </div>
        </>
    );
}

ShowFavouritesModal.propTypes = {
    /**
     * Content data
     */
    content: PropTypes.object,
};
