"use client";
import PropTypes from "prop-types";
import parser from "html-react-parser";
import { useState, useEffect, useRef } from "react";
// import sendUploadKYCFileRequest from "@/services/client/sendUploadKYCFileRequest";
import sendInternalPostRequest from "@/services/client/requests/sendInternalPostRequest";
import {
  useDispatchSetNonPassportUploadIsOpened,
  useDispatchShowLoader,
  useSelectNonPassportUpload,
  useSelectCsrfToken,
  useSelectAuthUser,
  useSelectLocale,
  useRedirect,
} from "@/hooks";
import moment from "moment";

export default function NonPassportUpload({ content = {} }) {
  const nonPassportUpload = useSelectNonPassportUpload();
  const csrfToken = useSelectCsrfToken();
  const authUser = useSelectAuthUser();
  const locale = useSelectLocale();
  const dispatchShowLoader = useDispatchShowLoader();
  const redirect = useRedirect();
  const dispatchSetNonPassportUploadIsOpened =
    useDispatchSetNonPassportUploadIsOpened();
  const [base64ImageFront, setBase64ImageFront] = useState("");
  const [selectedFileFront, setSelectedFileFront] = useState(null);
  const [base64ImageBack, setBase64ImageBack] = useState("");
  const [selectedFileBack, setSelectedFileBack] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const redirectRef = useRef();

  useEffect(() => {
    return () => {
      // Clean up for upload redirect
      if (redirectRef.current) {
        clearTimeout(redirectRef.current);
      }
    };
  }, []);

  if (Object.keys(content).length < 1)
    content = nonPassportUpload?.content || {};

  const isValidFile = (file = null) => {
    const errors = [];

    if (!content?.fileTypes?.includes(file?.type))
      errors.push(
        (content?.fileTypeError || "File type is not supported.") +
          " " +
          (content?.fileExtError ||
            "Allowed extensions are jpg, jpeg, png, pdf")
      );

    if (file?.size > 1024 * 1024 * 3) {
      errors.push(
        content?.fileSizeError || "File is too large. Maximum file size is 3MB."
      );
    }

    if (errors?.length > 0) {
      return errors.join("<br/>");
    }

    return true;
  };

  return (
    <>
      <div
        className="remodal-overlay remodal-is-opened"
        style={{
          display: !!nonPassportUpload?.isOpened ? "block" : "none",
        }}
      ></div>
      <div
        className="remodal-wrapper remodal-is-opened"
        style={{
          display: !!nonPassportUpload?.isOpened ? "block" : "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
          dispatchSetNonPassportUploadIsOpened(false);
        }}
      >
        <div
          className={
            "remodal modal modal--verification modal-verification remodal-is-initialized" +
            (!!nonPassportUpload?.isOpened
              ? " remodal-is-opened"
              : " remodal-is-closed")
          }
          data-remodal-id="modal-verification"
          tabIndex="-1"
        >
          <button
            className="remodal-close"
            data-remodal-action="close"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatchSetNonPassportUploadIsOpened(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </button>
          {parser(content?.content || "")}
          <form method="POST" id="form_verification">
            <div className="form-group form-group__buttons">
              <div className="form">
                <div className="form-group error">
                  <div className="error-text">
                    <p id="errmsg_id">{parser(errorMessage || "")}</p>
                  </div>
                </div>
              </div>
              <div
                className="btn--filebtn"
                data-title={content?.idFrontButton?.text || ""}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <input
                  id="kyc_file_id_front"
                  name="kyc_file_id_front"
                  type="file"
                  accept="image/jpeg,image/png,application/pdf"
                  onChange={async (e) => {
                    const file = e?.target?.files[0] || null;
                    const result = isValidFile(file);
                    if (result === true) {
                      setSelectedFileFront(file);
                      const reader = new FileReader();
                      reader.onload = function (loadEvent) {
                        const base64String =
                          loadEvent?.target?.result?.split(",") || [];
                        if (!!base64String[1]) {
                          setBase64ImageFront(base64String[1]);
                        }
                      };
                      await reader.readAsDataURL(file);
                    }
                    setErrorMessage(result === true ? "" : result);
                  }}
                />
              </div>
              <div
                className="btn--filebtn"
                data-title={content?.idBackButton?.text || ""}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <input
                  id="kyc_file_id_back"
                  name="kyc_file_id_back"
                  type="file"
                  accept="image/jpeg,image/png,application/pdf"
                  onChange={async (e) => {
                    const file = e?.target?.files[0] || null;
                    const result = isValidFile(file);
                    if (result === true) {
                      setSelectedFileBack(file);
                      const reader = new FileReader();
                      reader.onload = function (loadEvent) {
                        const base64String =
                          loadEvent?.target?.result?.split(",") || [];
                        if (!!base64String[1]) {
                          setBase64ImageBack(base64String[1]);
                        }
                      };
                      await reader.readAsDataURL(file);
                    }
                    setErrorMessage(result === true ? "" : result);
                  }}
                />
              </div>
              <a
                id="kyc_upload_id"
                className={
                  "btn btn--primary kyc" +
                  (isValidFile(selectedFileFront) !== true ||
                  isValidFile(selectedFileBack) !== true ||
                  !!errorMessage
                    ? " disabled"
                    : "")
                }
                href={content?.uploadButton?.link || ""}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (
                    isValidFile(selectedFileFront) === true &&
                    isValidFile(selectedFileBack) === true &&
                    !errorMessage
                  ) {
                    dispatchShowLoader(true);
                    const result = await sendInternalPostRequest({
                      endpoint: "/api/kyc/upload",
                      csrfToken,
                      contentType: "application/json",
                      data: {
                        type: "id",
                        base64ImageFront,
                        base64ImageBack,
                        dob: moment(
                          authUser?.profile?.dateOfBirth || "0000-00-00"
                        )
                          .locale("en")
                          .format("YYYY-MM-DD"),
                        fullName:
                          authUser?.profile?.firstName +
                          " " +
                          authUser?.profile?.lastName,
                        playerID: authUser?.profile?.playerID?.toString() || "",
                      },
                    });
                    if (result?.success) {
                      setSelectedFileFront(null);
                      setBase64ImageFront("");
                      setSelectedFileBack(null);
                      setBase64ImageBack("");
                      let statusUrl =
                        "/" +
                        locale +
                        "/my-account/verification-rejected?transactionId=" +
                        result?.transactionId;
                      if (result?.status === "accept")
                        statusUrl =
                          "/" +
                          locale +
                          "/my-account/verification-success?transactionId=" +
                          result?.transactionId;

                      redirectRef.current = setTimeout(() => {
                        redirect(statusUrl);
                        dispatchShowLoader(false);
                        dispatchSetNonPassportUploadIsOpened(false);
                      }, 8000);

                      return;
                    }
                    // const result =
                    //     await sendUploadKYCFileRequest({
                    //         file: selectedFile,
                    //         csrfToken,
                    //     });
                    dispatchShowLoader(false);
                  }
                }}
              >
                {content?.uploadButton?.text || ""}
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

NonPassportUpload.propTypes = {
  /**
   * Content data
   */
  content: PropTypes.object,
};
