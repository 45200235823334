const pushToDataLayer = (trackdata) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(trackdata);
  }
};

const pushToUIDataLayer = (trackdata) => {
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(trackdata);
  }
};

// Tracking the UI custom event
export const trackUIEvent = function (
  eventName,
  lang,
  currentpage,
  pageCategory,
  pageSubCategory,
  prevpage,
  login,
  id
) {
  let addDataLayer = {
    event: eventName,
    p_language: lang,
    p_pagename: currentpage,
    p_pagecategory: pageCategory,
    p_pagesubcategory: pageSubCategory,
    p_previouspagename: currentpage === prevpage ? "direct" : prevpage,
    p_loggedin: login ? "y" : "n",
    p_customerid: id ? id : "",
  };
  pushToDataLayer(addDataLayer);
};

export const trackClickEvent = function (
  eventName,
  category,
  action,
  labelname,
  lang,
  currentpage,
  pageCategory,
  pageSubCategory,
  prevpage,
  login,
  id
) {
  let addDataLayer = {
    event: eventName,
    event_category: category,
    event_action: action,
    event_label: labelname,
    p_language: lang,
    p_pagename: currentpage,
    p_pagecategory: pageCategory,
    p_pagesubcategory: pageSubCategory,
    p_previouspagename: currentpage === prevpage ? "direct" : prevpage,
    p_loggedin: login ? "y" : "n",
    p_customerid: id ? id : "",
  };
  pushToDataLayer(addDataLayer);
};

export const trackBuynowEvent = function (
  eventName,
  category,
  action,
  labelname,
  lang,
  currentpage,
  pageCategory,
  pageSubCategory,
  prevpage,
  login,
  id
) {
  let addDataLayer = {
    event: eventName,
    event_category: category,
    event_action: action,
    event_label: labelname,
    p_language: lang,
    p_pagename: currentpage,
    p_pagecategory: pageCategory,
    p_pagesubcategory: pageSubCategory,
    p_previouspagename: currentpage === prevpage ? "direct" : prevpage,
    p_loggedin: login ? "y" : "n",
    p_customerid: id ? id : "",
    p_position: "header",
    p_imagename: "boat",
  };
  pushToDataLayer(addDataLayer);
};

export const trackSigninEvent = function (
  eventName,
  category,
  action,
  province,
  lang,
  currentpage,
  pageCategory,
  pageSubCategory,
  prevpage,
  login,
  id,
  genderCode
) {
  let addDataLayer = {
    event: eventName,
    event_category: category,
    event_action: action,
    event_label: province,
    p_language: lang,
    p_pagename: currentpage,
    p_pagecategory: pageCategory,
    p_pagesubcategory: pageSubCategory,
    p_previouspagename: currentpage === prevpage ? "direct" : prevpage,
    p_loggedin: login ? "y" : "n",
    p_customerid: id ? id : "",
    p_gender: genderCode,
  };
  pushToDataLayer(addDataLayer);
};

export const trackCartEvents = function (dataLayer) {
  let addDataLayer = { ...dataLayer };
  pushToUIDataLayer(addDataLayer);
};
